import * as React from "react";
import { graphql } from "gatsby";
import PostLink from "../components/post-link";

// styles
const pageStyles = {
  color: "#232129",
  padding: 96,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}
const paragraphStyles = {
  marginBottom: 48,
}

// get the markdown files for building the index
export const pageQuery = graphql`
  query {
    allMdx(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
          slug
        }
      }
    }
  }
`

// process the markdown nodes via gql and output the index
const IndexPage = ({
  data: {
    allMdx: { edges },
  }
}) => {
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date)
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)
  return (
    <main style={pageStyles}>
      <title>md.bbxn.us MiniDisc Catalog</title>
      <h1 style={headingStyles}>
        RJ's MiniDisc catalog
      </h1>
      <p style={paragraphStyles}>
        This is a site indexing various MiniDiscs from <a href="https://twitter.com/arrjaybee">RJ</a> and friends.
      </p>
      <div>{Posts}</div>
      <p style={paragraphStyles}>
        <a href="https://gitlab.com/arrjay/minidisc-site">Source.</a>
      </p>
    </main>
  )
}

export default IndexPage
